import clsx from 'clsx';
import React, { useState } from 'react';

// eslint-disable-next-line import/no-cycle
import TariffTabsLayout from '@/components/blocks/tariffTabs/_deprecated/TariffTabsLayout';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Tabs from '@/components/tabs/Tabs';
import Title from '@/components/UI/Title';
import TabContext from '@/context/TabContext';
import { ITab } from '@/types/tabsBlock';
import { ITariffTabs } from '@/types/tariffTabs';

import cn from './style.module.sass';

const TariffTabs: React.FC<ITariffTabs> = ({ id, blocks, title, archive }) => {
    const [activeTab, setActiveTab] = useState(0);

    const setActiveTabHandler = (tab: number) => {
        setActiveTab(tab);
    };

    const mappedTabs = blocks.map(({ gtag, title: tabTitle }) => ({
        gtag,
        title: tabTitle,
    })) as ITab[];

    return (
        <div className={clsx(cn.tariffTabs, 'section', 'tariffTabs')} id={id}>
            <div className={clsx(cn.tariffTabsWrap, 'tabs')}>
                {title && <Title level={3} title={title} />}
                <TabContext.Provider value={{ activeTab, setActiveTabHandler }}>
                    <div className={cn.tabsContainer}>
                        <Tabs items={mappedTabs} archive={archive} />
                    </div>
                    <TariffTabsLayout blocks={blocks} />
                </TabContext.Provider>
            </div>
        </div>
    );
};

export default withBlockLinks(TariffTabs);
