import { Editor } from '@tiptap/core';
import Image from 'next/image';
import React, { useCallback, useRef, useState } from 'react';

import {
    headers,
    highlightColors,
    questionMarkColors,
    textAlignOptions,
    textColors,
} from '@/components/UI/TipTap/data';
import Feature from '@/domain/feature/Feature';
import useOnClickOutside from '@/hooks/useOnClickOutside';

type tableActionType = {
    action: () => void;
    label: string;
    url: string;
    title?: string;
};

type editorActionType = {
    action: () => void;
    className: string;
    url: string;
    title?: string;
};

type MenuBarProps = {
    editor: Editor | null;
    tableActions?: tableActionType[];
    editorActions: editorActionType[];
};

const MenuBar = ({ editor, editorActions, tableActions }: MenuBarProps) => {
    const [showColor, setShowColor] = useState(false);
    const [showHighlight, setShowHighlight] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [showTextAlign, setShowTextAlign] = useState(false);
    const [showQuestionMarks, setShowQuestionMarks] = useState(false);

    const headerRef = useRef<HTMLDivElement>();
    const highlightRef = useRef<HTMLDivElement>();
    const textAlignRef = useRef<HTMLDivElement>();
    const colorRef = useRef<HTMLDivElement>();
    const questionMarksRef = useRef<HTMLDivElement>();
    const isNewEditor = Feature.isFeatureEnabled('isNewEditor');

    const useOnclickOutsideHandler = () => {
        setShowColor(false);
        setShowHighlight(false);
        setShowHeader(false);
        setShowTextAlign(false);
        setShowQuestionMarks(false);
    };

    useOnClickOutside(headerRef, useOnclickOutsideHandler);
    useOnClickOutside(highlightRef, useOnclickOutsideHandler);
    useOnClickOutside(textAlignRef, useOnclickOutsideHandler);
    useOnClickOutside(colorRef, useOnclickOutsideHandler);
    useOnClickOutside(questionMarksRef, useOnclickOutsideHandler);

    const addQuestionMark = (color: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        const value = window.prompt('Введите текст подсказки');

        if (value) {
            editor.chain().focus().setComponent(value, color).run();
        }
        setShowQuestionMarks(false);
    };

    const addAnchor = () => {
        const anchor = window.prompt('Введите значение для ID (число)');

        if (anchor) {
            editor.chain().focus().setAnchor(anchor).run();
        }
    };
    const removeAnchor = () => {
        editor.chain().focus().unsetAnchor().run();
    };

    const addImage = useCallback(() => {
        const url = window.prompt('URL');

        if (url) {
            editor.chain().focus().setImage({ src: url }).run();
        }
    }, [editor]);

    const setLink = useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const previousUrl = editor.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);

        if (url === null) {
            return;
        }

        if (url === '') {
            editor.chain().focus().extendMarkRange('link').unsetLink().run();

            return;
        }

        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }, [editor]);

    const addIndent = () => {
        editor.chain().focus().setIndent().run();
        editor.chain().focus().updateClass().run();
    };

    if (!editor) {
        return null;
    }

    const toggleBulletList = () => {
        editor.chain().focus().setIndent().run();
        editor.chain().focus().toggleBulletList().run();
    };

    const toggleOrderedList = () => {
        editor.chain().focus().setIndent().run();
        editor.chain().focus().toggleOrderedList().run();
    };

    return (
        <div className="menuBar">
            <div className="handlerButton">
                <button onClick={() => setShowHeader(!showHeader)} type="button">
                    <Image
                        src="/img-next/svg/menu-bar/heading.svg"
                        alt="heading"
                        width={16}
                        height={16}
                        title="Размер заголовка"
                    />
                </button>
                {showHeader && (
                    <div className="popupMenu" ref={headerRef}>
                        {headers.map(({ headerLevel, title, url }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    setShowHeader(false);
                                    editor.chain().focus().toggleHeading({ level: headerLevel }).run();
                                }}
                                className={editor.isActive('heading', { level: headerLevel }) ? 'is-active' : ''}
                                key={title}
                            >
                                <Image src={url} alt={title} width={16} height={16} />
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <div className="handlerButton">
                <button type="button" onClick={() => setShowColor(!showColor)}>
                    <Image
                        src="/img-next/svg/menu-bar/font-color.svg"
                        alt="font-color"
                        width={16}
                        height={16}
                        title="Цвет текста"
                    />
                </button>
                {showColor && (
                    <div className="popupMenu" ref={colorRef}>
                        {textColors.map(({ color, title }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    setShowColor(false);
                                    editor.chain().focus().setColor(color).run();
                                }}
                                className={editor.isActive('textStyle', { color }) ? 'is-active' : ''}
                                data-testid={title}
                                key={color}
                            >
                                <div style={{ backgroundColor: color, width: 15, height: 15 }} />
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <div className="handlerButton">
                <button type="button" onClick={() => setShowHighlight(!showHighlight)}>
                    <Image
                        src="/img-next/svg/menu-bar/mark-pen-line.svg"
                        alt="mark-pen"
                        width={16}
                        height={16}
                        title="Цвет фона"
                    />
                </button>
                {showHighlight && (
                    <div className="popupMenu" ref={highlightRef}>
                        {highlightColors.map(({ color }) => (
                            <button
                                type="button"
                                onClick={() => {
                                    setShowHighlight(false);
                                    editor.chain().focus().toggleHighlight({ color }).run();
                                }}
                                className={editor.isActive('highlight', { color }) ? 'is-active' : ''}
                                key={color}
                            >
                                <div style={{ backgroundColor: color, width: 15, height: 15 }} />
                            </button>
                        ))}
                    </div>
                )}
            </div>

            {editorActions.map(({ action, url, className, title }, index) => (
                <button
                    type="button"
                    onClick={action}
                    className={editor.isActive(className) ? 'is-active' : ''}
                    key={index}
                >
                    <Image src={url} alt={className} width={16} height={16} title={title} />
                </button>
            ))}

            <button
                type="button"
                onClick={toggleBulletList}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
            >
                <Image
                    src="/img-next/svg/menu-bar/list-unordered.svg"
                    alt="bulletList"
                    width={16}
                    height={16}
                    title="список"
                />
            </button>
            <button
                type="button"
                onClick={toggleOrderedList}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
            >
                <Image
                    src="/img-next/svg/menu-bar/list-ordered.svg"
                    alt="orderedList"
                    width={16}
                    height={16}
                    title="нумерованный список"
                />
            </button>

            <div className="handlerButton">
                <button type="button" onClick={() => setShowTextAlign(!showTextAlign)}>
                    <Image
                        src="/img-next/svg/menu-bar/align-left.svg"
                        alt="text-align"
                        width={16}
                        height={16}
                        title="выравнивание"
                    />
                </button>
                {showTextAlign && (
                    <div className="popupMenu" ref={textAlignRef}>
                        {textAlignOptions.map(({ align, url }, index) => (
                            <button
                                type="button"
                                onClick={() => {
                                    setShowTextAlign(false);
                                    editor.chain().focus().setTextAlign(align).run();
                                }}
                                className={editor.isActive({ textAlign: align }) ? 'is-active' : ''}
                                key={index}
                            >
                                <Image src={url} alt={align} width={16} height={16} />
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <button type="button" onClick={addIndent} className={editor.isActive('indent-1') ? 'is-active' : ''}>
                <Image
                    src="/img-next/svg/menu-bar/indent-increase.svg"
                    alt="image"
                    width={16}
                    height={16}
                    title="увеличить отступ"
                />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().unsetIndent().run()}
                className={editor.isActive('indent') ? 'is-active' : ''}
            >
                <Image
                    src="/img-next/svg/menu-bar/indent-decrease.svg"
                    alt="image"
                    width={16}
                    height={16}
                    title="уменьшить отступ"
                />
            </button>

            <button
                type="button"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
            >
                <Image
                    src="/img-next/svg/menu-bar/double-quotes-r.svg"
                    alt="image"
                    width={16}
                    height={16}
                    title="выделение блока"
                />
            </button>
            <button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
                <Image
                    src="/img-next/svg/menu-bar/separator.svg"
                    alt="separator"
                    width={16}
                    height={16}
                    title="разделитель"
                />
            </button>

            <div>
                <button type="button" onClick={addImage}>
                    <Image
                        src="/img-next/svg/menu-bar/image-line.svg"
                        alt="image"
                        width={16}
                        height={16}
                        title="добавить изображение"
                    />
                </button>
            </div>

            <div className="handlerButton">
                <button type="button" onClick={() => setShowQuestionMarks(!showQuestionMarks)}>
                    <Image
                        src="/img-next/svg/menu-bar/question-line.svg"
                        alt="mark-pen"
                        width={16}
                        height={16}
                        title="добавить тултип"
                    />
                </button>
                {showQuestionMarks && (
                    <div className="popupMenu" ref={questionMarksRef}>
                        {questionMarkColors.map(({ color, url }, index) => (
                            <button
                                type="button"
                                onClick={addQuestionMark(color)}
                                className={editor.isActive('content') ? 'is-active' : ''}
                                key={index}
                            >
                                <Image src={url} alt={color} width={14} height={14} />
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <button type="button" onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
                <Image
                    src="/img-next/svg/menu-bar/link-m.svg"
                    alt="link"
                    width={16}
                    height={16}
                    title="добавить ссылку"
                />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().unsetLink().run()}
                disabled={!editor.isActive('link')}
            >
                <Image
                    src="/img-next/svg/menu-bar/link-unlink-m.svg"
                    alt="unlink"
                    width={16}
                    height={16}
                    title="открепить ссылку"
                />
            </button>
            <button type="button" onClick={addAnchor}>
                <Image
                    src="/img-next/svg/menu-bar/anchor.svg"
                    alt="anchor"
                    width={16}
                    height={16}
                    title="добавить якорь"
                />
            </button>
            <button type="button" onClick={removeAnchor}>
                <Image
                    src="/img-next/svg/menu-bar/unsetAnchor.svg"
                    alt="removeAnchor"
                    width={16}
                    height={16}
                    title="убрать якорь"
                />
            </button>
            {isNewEditor && (
                <>
                    {tableActions.map(({ action, label, url, title }) => (
                        <button type="button" key={label} onClick={action}>
                            <Image src={url} alt={label} width={16} height={16} title={title} />
                        </button>
                    ))}
                </>
            )}

            <button
                type="button"
                onClick={() => editor.chain().focus().undo().run()}
                disabled={!editor.can().chain().focus().undo().run()}
            >
                <Image
                    src="/img-next/svg/menu-bar/delete-back.svg"
                    alt="undo"
                    width={16}
                    height={16}
                    title="отменить последнее действие"
                />
            </button>
            <button
                type="button"
                onClick={() => editor.chain().focus().redo().run()}
                disabled={!editor.can().chain().focus().redo().run()}
            >
                <Image
                    src="/img-next/svg/menu-bar/redo.svg"
                    alt="redo"
                    width={16}
                    height={16}
                    title="вернуть последнее действие"
                />
            </button>
        </div>
    );
};

export default MenuBar;
