import React from 'react';
import { wrapFieldsWithMeta } from 'tinacms';

import Feature from '@/domain/feature/Feature';
import HtmlEditor from '@/tina/plugins/htmlEditor';
import HtmlEditorNew from '@/tina/plugins/htmlEditorNew';

const HtmlEditorFeatured = wrapFieldsWithMeta(({ field, input, meta }) => {
    const isNewEditor = Feature.isFeatureEnabled('isNewEditor');
    if (!isNewEditor) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        return <HtmlEditor field={field} input={input} meta={meta} />;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,react/react-in-jsx-scope
    return <HtmlEditorNew input={input} />;
});

export default HtmlEditorFeatured;
