import { ITinaBlock } from '@/types/tina/config';

export const setBlockLinks = (config: ITinaBlock) => ({
    ...config,
    template: {
        ...config?.template,
        fields: [
            {
                component: 'text',
                name: 'productMenuLink',
                label: 'Ссылка на блок для продуктовой панели',
            },
            {
                component: 'text',
                name: 'newSectionName',
                label: 'Переименовать существующий раздел',
            },
            ...config.template.fields,
        ],
    },
});
