import clsx from 'clsx';
import React from 'react';

import cn from '@/components/blocks/tariffTabs/TariffBlocks/style.module.sass';
import CustomImage from '@/components/CustomImage';
import CustomTooltip from '@/components/Tooltip';
import TooltipIcon from '@/components/UI/TooltipIcon';
import { ITariffItemContent } from '@/types/tariffBlocks';

const TariffItemContent: React.FC<ITariffItemContent> = ({ info, conditions, isConditionsActive }) => (
    <>
        {info?.map(({ name, desc, text, isBigName, isMain, tooltipText }, index) => (
            <div
                className={clsx(cn.tariffRow, isMain && cn.tariffRowMain, isBigName && cn.tariffRowBigName)}
                key={index}
            >
                <div className={clsx(cn.tariffItem, cn.tariffItemName)}>
                    <div className={cn.tariffNameWrap}>
                        <div className={cn.tariffName} dangerouslySetInnerHTML={{ __html: name }} />
                        {tooltipText && (
                            <CustomTooltip
                                className={cn.tooltip}
                                html={<div className={cn.tooltipText}>{tooltipText}</div>}
                            >
                                <TooltipIcon type="question" />
                            </CustomTooltip>
                        )}
                    </div>
                    {text && <div className={cn.tariffText} dangerouslySetInnerHTML={{ __html: text }} />}
                </div>
                <div className={clsx(cn.tariffItem, cn.tariffItemDesc)}>
                    <div className={cn.tariffDesc} dangerouslySetInnerHTML={{ __html: desc }} />
                </div>
            </div>
        ))}

        {(conditions?.subhead || conditions?.text) && (
            <div className={clsx(cn.conditions, isConditionsActive === false && cn.hidden)}>
                <div className={cn.conditionsGrid}>
                    <div className={clsx(cn.conditionsItem, cn.conditionsItemContent)}>
                        <div className={cn.conditionsContent}>
                            <div
                                className={cn.conditionsTitle}
                                dangerouslySetInnerHTML={{
                                    __html: conditions.subhead,
                                }}
                            />
                            <div
                                className={cn.conditionsDesc}
                                dangerouslySetInnerHTML={{
                                    __html: conditions.text,
                                }}
                            />
                        </div>
                    </div>
                    <div className={clsx(cn.conditionsItem, cn.conditionsItemImage)}>
                        <div className={cn.conditionsImage}>
                            {conditions?.image && (
                                <CustomImage src={conditions.image} alt={conditions.subhead} width={165} height={165} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
);

export default TariffItemContent;
