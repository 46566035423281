import { observer } from 'mobx-react-lite';
import React from 'react';

import { useTinaLibStore } from '@/hooks/useTina';
import { tariffTabsTinaConfig } from '@/tina/config/tariffTabs.tina.config';
import { getBlocks } from '@/tina/utils/getBlocks';

const TinaLayout: React.FC = observer(() => {
    const { TinaInline } = useTinaLibStore();
    const { InlineBlocks } = TinaInline;

    const blocks = getBlocks(tariffTabsTinaConfig);

    return <InlineBlocks direction="horizontal" name="blocks" blocks={blocks} />;
});

export default TinaLayout;
