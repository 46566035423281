import React from 'react';

import { TinaHelp } from '@/components/blocks/tariffTabs/Help/index.tina.deprecated';
import { mockHelpBlockData } from '@/data/blocks/tariffTabs/help';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const helpBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaHelp {...extendedProps} />;
    },
    template: {
        label: 'Помощь',
        defaultItem: () => mockHelpBlockData,
        fields: [
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { subtitle: string }, index: number) => ({
                    key: index,
                    label: item.subtitle,
                }),
                defaultItem: () => mockHelpBlockData.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'subtitle',
                        label: 'Заголовок',
                    },
                    {
                        component: HtmlEditorFeatured,
                        name: 'desc',
                        label: 'Описание',
                    },
                ],
            },
        ],
    },
};
