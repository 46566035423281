export const mockHelpBlockData = {
    title: 'Помощь',
    isIndexHidden: true,
    list: [
        {
            subtitle: 'Какие требования к заемщику?',
            desc: '<ul><li>Паспорт: гражданина РФ</li><li>Постоянная регистрация (прописка): в любом регионе России</li><li>Возраст: от 18 лет</li><li>Телефон: мобильный или рабочий (если у вас есть работа)</li><li>СНИЛС: обязательно</li><li>Справка о доходах: не нужна</li></ul>',
        },
        {
            subtitle: 'Какие документы необходимы для получения кредитной карты?',
            desc: '<p>Для получения кредита необходимо пройти несложную процедуру заполнения заявки и сдачи документов в отделении банка. Взять займ в Почта Банке можно по двум документам:</p><ul><li><b>Паспорт.</b> В нем должно быть указано, что человек достиг совершеннолетнего возраста и является гражданином России, должна стоять метка о постоянной регистрации в данной стране. Без этого кредит человеку в Почта Банке не выдадут.</li><li><p><b>СНИЛС.</b> Он поможет банку просмотреть трудовой стаж клиента и сделать вывод о его финансовой платежеспособности и ответственности.</p></li></ul>',
        },
        {
            subtitle: 'Как подать заявку?',
            desc: '<p><a href="#">«Почта Банк»</a> — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Будет выпущена именная или неименная карта?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Как выпустить виртуальную карту?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Сколько стоит выпуск и обслуживание?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Какой кредитный лимит по карте?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Как увеличить кредитный лимит?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Банк увеличил мне кредитный лимит?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Как действует беспроцентный период по карте?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Что необходимо сделать для восстановления беспроцентного периода по карте?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Когда происходит восстановление кредитного лимита после полного погашения задолженности?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Влияет ли пропуск платежа на прекращение действия беспроцентного периода?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
        {
            subtitle: 'Доступно ли снятие наличных с кредитной карты?',
            desc: '<p>«Почта Банк» — универсальный розничный банк, созданный в январе 2016 года, созданный при участии Почты России и Банка ВТБ. Банк развивает региональную сеть на базе отделений Почты России. По итогам 9 месяцев 2017 года было открыто 12500 точек оказания услуг банка более чем в 80 регионах РФ, а клиентская база превысила 5 млн человек. «Почта Банк» занимает второе место по размеру розничной сети на российском банковском рынке. В почтовых отделениях банк представлен в формате окон продаж с сотрудником банка или с сотрудником Почты России.</p>',
        },
    ],
};

export const mockHelpBlockDataTina = {
    section: null,
    title: 'Заголовок',
    isIndexHidden: true,
    list: [
        {
            subtitle: 'Заголовок',
            desc: 'Описание',
        },
    ],
};
