import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import Arrow from '@/components/svg/Arrow';
import CustomAccordion from '@/components/UI/CustomAccordion';

import cn from './style.module.sass';

export interface IHelpItem {
    subtitle: string;
    desc: string;
}

const getTrigger = (trigger: string, pos: number) => (
    <div className={clsx(cn.triggerWrap)}>
        <div className={cn.trigger} dangerouslySetInnerHTML={{ __html: trigger }} />
        <div className={cn.arrow}>
            <Arrow />
        </div>
    </div>
);

const HelpItem: React.FC<IHelpItem & { pos: number }> = ({ subtitle, pos, desc }) => (
    <AnimatedComponent className={cn.helpCollapsibleWrap} classNameActive={cn.animationInit} key={pos}>
        <CustomAccordion trigger={getTrigger(subtitle, pos)}>
            <div className={cn.helpDesc} suppressHydrationWarning dangerouslySetInnerHTML={{ __html: desc }} />
        </CustomAccordion>
    </AnimatedComponent>
);
export default HelpItem;
