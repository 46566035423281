import React, { useContext } from 'react';

import TinaLibContext from '@/context/TinaLibContext';

const withBlocksControl =
    Component =>
    ({ index, data }: { index: number; data: unknown }) => {
        const { TinaInline } = useContext(TinaLibContext);
        const { BlocksControls } = TinaInline;

        return (
            <BlocksControls insetControls focusRing={{ offset: 0 }} index={index}>
                {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/*  @ts-ignore */}
                <Component {...data} />
            </BlocksControls>
        );
    };

export default withBlocksControl;
