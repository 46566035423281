export const mockTabTariffBlockData = {
    title: 'Тарифы',
    list: [
        {
            title: 'Тариф «Прайм-Суперпочтовый»',
            info: [
                {
                    name: 'Процентная ставка',
                    desc: 'от 5,9%',
                },
                {
                    name: 'Сумма кредита',
                    desc: '50 000 — 4 000 000 Р',
                },
                {
                    name: 'Размер ежемесячного платежа',
                    desc: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    name: 'Срок кредита',
                    desc: '36, 48, 60 месяцев',
                },
            ],
            isConditionsActive: true,
            conditions: {
                image: '/img-next/png/conditions.png',
                subhead: 'Специальные условия применяются:',
                text: '<ul><li>при переводе пенсии на счёт в Почта Банк</li><li>при оплате картами Почта Банка товаров и услуг на сумму от 10 000 Р в месяц</li></ul>',
            },
        },
        {
            title: 'Тариф «Снижаем ставку, Снижаем ставку+, Снижаем ставку Промо, Снижаем ставку+ Промо, Снижаем ставку Минимум, Снижаем ставку+ Минимум»',
            info: [
                {
                    name: 'Процентная ставка',
                    desc: '3,9% в пятый год действия кредитного договора',
                },
                {
                    name: 'Сумма кредита',
                    desc: '500 000 — 6 000 000 Р',
                },
                {
                    name: 'Размер ежемесячного платежа',
                    desc: 'Зависит от суммы, срока и ставки по кредиту',
                },
                {
                    name: 'Срок кредита',
                    desc: '36, 48, 60 месяцев',
                },
            ],
            isConditionsActive: false,
        },
        {
            title: 'Общие условия для всех тарифов',
            info: [
                {
                    name: 'Способы погашения',
                    desc: '<p>Без комиссии:</p><p>— В банкоматах Почта Банка</p><p>— В банкоматах банков группы ВТБ (с внесением денег на карту Почта Банка)</p><p>— С карт других банков в Почта Банк Онлайн (от 10 000 i)</p><p><a href="#">Другие способы погашения</a></p>',
                },
                {
                    name: 'Досрочное погашение',
                    desc: 'Бесплатно. Частичное или полное досрочное погашение доступно без комиссии и заявлений',
                },
                {
                    name: 'Финансовая защита',
                    desc: 'Доступный выбор одной из <a href="#">программ страхования</a>',
                },
                {
                    name: 'Кредитные каникулы',
                    desc: 'Возможность оформить заявление на получение отсрочки по кредиту',
                },
            ],
            isConditionsActive: false,
        },
    ],
};

export const mockTabTariffBlockDataTina = {
    section: null,
    title: 'Заголовок',
    list: [
        {
            title: 'Заголовок',
            info: [
                {
                    name: 'Заголовок',
                    desc: 'Описание',
                },
            ],
            isConditionsActive: false,
        },
    ],
};
