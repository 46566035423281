import { ITinaBlock } from '@/types/tina/config';

export const setSectionField = (config: ITinaBlock, _template: string, exceptions: string[]) => {
    if (exceptions.includes(_template)) return config;

    const alreadyHasSection = !!config.template.fields.filter(
        (field: { component: string }) => field?.component === 'section'
    ).length;

    if (alreadyHasSection) return config;

    return {
        ...config,
        template: {
            ...config.template,
            fields: [
                {
                    name: 'section',
                    component: 'section',
                    label: 'Привязка к разделу',
                },
                ...config.template.fields,
            ],
        },
    };
};
