import { EditorContent, useEditor } from '@tiptap/react';
import React from 'react';

import MenuBar from '@/components/UI/TipTap/MenuBar';
import { useExtensions } from '@/hooks/useExtensions';

const HtmlEditorNew = ({ input }) => {
    const { loaded, extensions } = useExtensions();

    const editor = useEditor(
        {
            extensions,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            content: input.value as string,
            editorProps: {},
            onUpdate: () => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                input.onChange(editor.getHTML());
            },
        },
        [loaded]
    );

    const tableActions = [
        {
            label: 'insertTable',
            action: () => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run(),
            url: '/img-next/svg/menu-bar/table-3.svg',
            title: 'добавить таблицу',
        },
        {
            label: 'addColumnBefore',
            action: () => editor.chain().focus().addColumnBefore().run(),
            url: '/img-next/svg/menu-bar/insert-column-left.svg',
            title: 'добавить колонку слева',
        },
        {
            label: 'addColumnAfter',
            action: () => editor.chain().focus().addColumnAfter().run(),
            url: '/img-next/svg/menu-bar/insert-column-right.svg',
            title: 'добавить колонку справа',
        },
        {
            label: 'deleteColumn',
            action: () => editor.chain().focus().deleteColumn().run(),
            url: '/img-next/svg/menu-bar/delete-column.svg',
            title: 'удалить колонку',
        },
        {
            label: 'addRowBefore',
            action: () => editor.chain().focus().addRowBefore().run(),
            url: '/img-next/svg/menu-bar/insert-row-top.svg',
            title: 'добавить ряд сверху',
        },
        {
            label: 'addRowAfter',
            action: () => editor.chain().focus().addRowAfter().run(),
            url: '/img-next/svg/menu-bar/insert-row-bottom.svg',
            title: 'добавить ряд снизу',
        },
        {
            label: 'deleteRow',
            action: () => editor.chain().focus().deleteRow().run(),
            url: '/img-next/svg/menu-bar/delete-row.svg',
            title: 'удалить ряд',
        },
        {
            label: 'deleteTable',
            action: () => editor.chain().focus().deleteTable().run(),
            url: '/img-next/svg/menu-bar/delete-bin.svg',
            title: 'удалить таблицу',
        },
        {
            label: 'mergeCells',
            action: () => editor.chain().focus().mergeCells().run(),
            url: '/img-next/svg/menu-bar/merge-cells-horizontal.svg',
            title: 'объединить ячейки',
        },
        {
            label: 'splitCell',
            action: () => editor.chain().focus().splitCell().run(),
            url: '/img-next/svg/menu-bar/split-cells-horizontal.svg',
            title: 'разделить ячейки',
        },
    ];

    const editorActions = [
        {
            action: () => editor.chain().focus().toggleBold().run(),
            className: 'bold',
            url: '/img-next/svg/menu-bar/bold.svg',
            title: 'жирный текст',
        },
        {
            action: () => editor.chain().focus().toggleItalic().run(),
            className: 'italic',
            url: '/img-next/svg/menu-bar/italic.svg',
            title: 'курсивный текст',
        },
        {
            action: () => editor.chain().focus().toggleStrike().run(),
            className: 'strike',
            url: '/img-next/svg/menu-bar/strikethrough.svg',
            title: 'зачеркнутый текст',
        },
        {
            action: () => editor.chain().focus().toggleUnderline().run(),
            className: 'underline',
            url: '/img-next/svg/menu-bar/underline.svg',
            title: 'подчеркивание',
        },
        {
            action: () => editor.chain().focus().toggleCode().run(),
            className: 'code',
            url: '/img-next/svg/menu-bar/code-line.svg',
            title: 'код',
        },
        {
            action: () => editor.chain().focus().toggleSubscript().run(),
            className: 'subscript',
            url: '/img-next/svg/menu-bar/subscript.svg',
            title: 'нижний индекс',
        },
        {
            action: () => editor.chain().focus().unsetAllMarks().run(),
            url: '/img-next/svg/menu-bar/format-clear.svg',
            className: 'format-clear',
            title: 'отмена стилей',
        },
    ];

    if (loaded) return null;

    return (
        <div className="editor">
            <MenuBar editor={editor} editorActions={editorActions} tableActions={tableActions} />
            <EditorContent editor={editor} />
        </div>
    );
};

export default HtmlEditorNew;
