export const sectionExceptionsConfig = [
    'tabs',
    'paymentGroup',
    'tariffTabs',
    'calculatorsGroup',
    'preferentialCalculator',
    'cashCalculator',
    'vezdedohodCalculator',
    'search',
    'sberschetCalculator',
    'studyCalculator',
    'rkoCalculator',
    'creditCalculator',
    'popularExchangeRates',
    'allExchangeRates',
    'pensionTransfer',
    'map',
    'pushkinskayaCard',
    'sberschetOpen',
    'vddohod',
    'cashLoan',
    'loanRepayment',
    'cardToCard',
    'depositApplication',
    'forestMap',
    'services',
    'unsubscribeNews',
    'onlineStorePartners',
    'newYearQuiz',
    'preferentialCalculator',
    'secondaryPreferentialCalculator',
    'pledgeCalculator',
    'buildingCalculator',
    'buildingCalculatorWithPicture',
    'feedbackUlIpSurvey',
    'universalExternalFormScript',
    'universalExternalIframe',
    'pboFaq',
    'pboFaqDetail',
];
