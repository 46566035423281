import { documentsBlock } from '@/tina/blocks/tariffTabs/_deprecated/documents';
import { helpBlock } from '@/tina/blocks/tariffTabs/_deprecated/help';
import { tableBlock } from '@/tina/blocks/tariffTabs/_deprecated/table';
import { tabTariffBlock } from '@/tina/blocks/tariffTabs/_deprecated/tariff';

export const tariffTabsTinaConfig = {
    help: helpBlock,
    documents: documentsBlock,
    tariff: tabTariffBlock,
    table: tableBlock,
};
