import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import AnimatedComponent from '@/components/Animation';
import CustomSlider from '@/components/CustomSlider';
import TextField from '@/components/TextField';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

export type ITable = {
    _template: 'table';
    html: string;
    type?: 'main' | 'secondary' | 'third';
    title: string;
    text?: string;
    isActive: boolean;
};

export type IRowItem = {
    listCell: ICellItem[];
};

export type ICellItem = {
    label: string;
    text: string;
};

const Table: FC<ITable> = observer(({ title, text, html, type = 'main', isActive = false }) => {
    const isNewEditor = Feature.isFeatureEnabled('isNewEditor');

    return (
        <AnimatedComponent>
            <div className="section">
                <div className={cn.wrap}>
                    <div className={cn.top}>
                        <TextField className={cn.title} text={title} name="title" isHTML htmlValue={title} />
                        {text && <TextField className={cn.text} text={text} name="text" />}
                    </div>
                    <CustomSlider className={cn.slider}>
                        <TextField
                            isHTML
                            htmlValue={html}
                            className={clsx(
                                type === 'main' && cn.tableWrap,
                                type === 'main' && isActive && cn.active,
                                type === 'secondary' && cn.tableWrapSecondary,
                                type === 'third' && cn.tableWrapThird,
                                isNewEditor && cn.isNewEditor
                            )}
                            name="html"
                            text={html}
                            offset={0}
                        />
                    </CustomSlider>
                </div>
            </div>
        </AnimatedComponent>
    );
});

export default Table;
