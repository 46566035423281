import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import HelpContent from '@/components/blocks/tariffTabs/Help/Content';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';

import { IHelpItem } from './item';
import cn from './style.module.sass';

export interface IHelp {
    _template: 'help';
    title: string;
    isIndexHidden: boolean;
    list: IHelpItem[];
}

const Help: React.FC<IHelp & { index: number }> = ({ list, title, index, isIndexHidden = true }) => (
    <AnimatedComponent className={clsx(cn.helpBlock, 'section')} classNameActive={cn.animationInit}>
        {isIndexHidden ? (
            <noindex>
                <HelpContent title={title} index={index} list={list} />
            </noindex>
        ) : (
            <HelpContent title={title} index={index} list={list} />
        )}
    </AnimatedComponent>
);

export default withBlockLinks(Help);
