export const mockDocumentsBlockData = {
    title: 'Документы',
    isCollapsible: false,
    list: [
        {
            documentName: 'Тарифы АО «Почта Банк» на оказание услуг интернет-эквайринга',
            document: '#',
            expirationDate: '',
            documentDescription:
                'Дата размещения отчетности на сайте (27.05.2021), Дата направления в Банк России (27.05.2021)',
            documentType: 'pdf',
            documentSize: '1.1 Mb',
        },
        {
            documentName: 'Тарифы АО «Почта Банк» на оказание услуг интернет-эквайринга',
            document: '#',
            expirationDate: '',
            documentDescription:
                'Дата размещения отчетности на сайте (27.05.2021), Дата направления в Банк России (27.05.2021)',
            documentType: 'pdf',
            documentSize: '1.1 Mb',
        },
        {
            documentName: 'Тарифы АО «Почта Банк» на оказание услуг интернет-эквайринга',
            document: '#',
            expirationDate: '',
            documentDescription:
                'Дата размещения отчетности на сайте (27.05.2021), Дата направления в Банк России (27.05.2021)',
            documentType: 'pdf',
            documentSize: '1.1 Mb',
        },
        {
            documentName: 'Тарифы АО «Почта Банк» на оказание услуг интернет-эквайринга',
            document: '#',
            expirationDate: '',
            documentDescription:
                'Дата размещения отчетности на сайте (27.05.2021), Дата направления в Банк России (27.05.2021)',
            documentType: 'pdf',
            documentSize: '1.1 Mb',
        },
    ],
};

export const mockDocumentsBlockDataTina = {
    section: null,
    title: 'Заголовок',
    isCollapsible: false,
    list: [
        {
            documentName: 'Заголовок',
            document: '#',
            expirationDate: '',
            documentDescription: 'Описание',
            documentType: '',
        },
    ],
};
