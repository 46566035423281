import React from 'react';

import HelpItem, { IHelpItem } from '@/components/blocks/tariffTabs/Help/item';
import cn from '@/components/blocks/tariffTabs/Help/style.module.sass';
import TextField from '@/components/TextField';

interface IHelpContent {
    title: string;
    list: IHelpItem[];
    index: number;
}

const HelpContent = ({ title, list, index }: IHelpContent) => (
    <div className={cn.helpBlockWrap}>
        {title && (
            <TextField text={title} className={cn.mainTitle} name="title" customTag="h3" isHTML htmlValue={title} />
        )}
        <div className={cn.fullWrapper}>
            <div className={cn.help}>
                {list?.map(({ subtitle, desc }, lastIndex) => (
                    <HelpItem key={lastIndex} pos={index} subtitle={subtitle} desc={desc} />
                ))}
            </div>
        </div>
    </div>
);

export default HelpContent;
