import clsx from 'clsx';
import React, { useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import Block from '@/components/layout/Block';
import TabContext from '@/context/TabContext';
import { tariffTabsConfig } from '@/tina/config/tariffTabs.config';
import { IDefaultBlock } from '@/types/tina/block';

import cn from './style.module.sass';

interface IProps {
    blocks: IDefaultBlock[];
}

const DefaultLayout: React.FC<IProps> = ({ blocks }) => {
    const { activeTab } = useContext(TabContext);

    return (
        <div className={cn.wrapper}>
            {blocks.map((block, index) => (
                <div key={index} className={clsx(cn.block, activeTab !== index && cn.hide)}>
                    <Block block={block} index={index} config={tariffTabsConfig} />
                </div>
            ))}
        </div>
    );
};

export default DefaultLayout;
