import React from 'react';

import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { ITariffItem } from '@/types/tariffBlocks';

import TariffItem from './item';
import cn from './style.module.sass';

export interface ITariffBlocks {
    _template: 'tariffBlocks';
    type?: 'main' | 'secondary';
    title?: string;
    list: ITariffItem[];
}

const TariffBlocks: React.FC<ITariffBlocks & { index: number }> = ({ list, type = 'main' }) => (
    <div className="section">
        <div className={cn.fullWrapper}>
            <div className={cn.tariff}>
                {list?.map(({ title, info, isConditionsActive, conditions, ...rest }, listIndex) => (
                    <TariffItem
                        containerType={type}
                        key={listIndex}
                        pos={listIndex}
                        title={title}
                        info={info}
                        {...rest}
                        isConditionsActive={isConditionsActive}
                        conditions={conditions}
                    />
                ))}
            </div>
        </div>
    </div>
);

export default withBlockLinks(TariffBlocks);
