import { Level } from '@tiptap/extension-heading';

type Headers = {
    headerLevel: Level;
    title: string;
    url: string;
}[];

export const questionMarkColors = [
    { color: 'light', url: '/img-next/svg/questionmark-light.svg' },
    { color: 'dark', url: '/img-next/svg/questionmark-dark.svg' },
];

export const textColors = [
    { color: '#958DF1', title: 'lilac' },
    { color: '#F98181', title: 'red' },
    { color: '#FCE883', title: 'yellow' },
    { color: '#FFFFFF', title: 'white' },
    { color: '#9400d3', title: 'purple' },
    { color: '#000000', title: 'black' },
];
export const highlightColors = [
    { color: '#F4F4FF', title: 'lightBlue' },
    { color: '#D0F0C0', title: 'green' },
    { color: '#FCE883', title: 'yellow' },
    { color: '#FFFFFF', title: 'white' },
    { color: '#9400d3', title: 'purple' },
    { color: '#000000', title: 'black' },
];

export const textAlignOptions = [
    { align: 'left', url: '/img-next/svg/menu-bar/align-left.svg' },
    { align: 'center', url: '/img-next/svg/menu-bar/align-center.svg' },
    { align: 'right', url: '/img-next/svg/menu-bar/align-right.svg' },
    { align: 'justify', url: '/img-next/svg/menu-bar/align-justify.svg' },
];

export const headers: Headers = [
    { headerLevel: 1, title: 'h1', url: '/img-next/svg/menu-bar/h-1.svg' },
    { headerLevel: 2, title: 'h2', url: '/img-next/svg/menu-bar/h-2.svg' },
    { headerLevel: 3, title: 'h3', url: '/img-next/svg/menu-bar/h-3.svg' },
    { headerLevel: 4, title: 'h4', url: '/img-next/svg/menu-bar/h-4.svg' },
    { headerLevel: 5, title: 'h5', url: '/img-next/svg/menu-bar/h-5.svg' },
    { headerLevel: 6, title: 'h6', url: '/img-next/svg/menu-bar/h-6.svg' },
];
