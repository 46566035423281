import React from 'react';

import cn from './style.module.sass';

function Arrow() {
    return (
        <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L7.5 7L14 1"
                className={cn.svgStroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default Arrow;
