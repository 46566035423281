import clsx from 'clsx';
import { parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import React from 'react';

import AnimatedComponent from '@/components/Animation';

import cn from './style.module.sass';

export interface IDocumentsItem {
    documentName: string;
    document: string;
    permanentDocument?: string;
    documentType?: string;
    documentSize?: string;
    documentDescription?: string;
    expirationDate?: string;
}

const format = 'dd.MM.yyyy HH:mm:ss';
const timeZone = 'Europe/Moscow';
const DocumentsItem: React.FC<IDocumentsItem & { pos: number }> = ({
    pos,
    permanentDocument,
    documentName,
    document,
    documentType,
    documentSize,
    documentDescription,
    expirationDate,
}) => {
    const parsedExpirationDate = parse(expirationDate, format, new Date());
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment
    const expirationDateMoscowTime = utcToZonedTime(parsedExpirationDate, timeZone);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
    const currentMoscowTime = utcToZonedTime(new Date(), timeZone);

    if (expirationDate && expirationDateMoscowTime < currentMoscowTime) return null;

    return (
        <AnimatedComponent className={cn.documentsLink} classNameActive={cn.animationInit}>
            <div className={cn.documentsGrid}>
                <a
                    href={permanentDocument || document}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={cn.documentsWrap}
                >
                    <div
                        className={clsx(
                            cn.documentsItem,
                            cn.documentsItemIcon,
                            documentSize && cn.documentsItemIconLarge
                        )}
                    >
                        <div className={cn.documentsItemIconContainer}>
                            <div className={clsx(cn.documentsIcon, cn[`documentsItem-${documentType}`])} />
                            {documentSize && <div className={cn.documentsSize}>{documentSize}</div>}
                        </div>
                    </div>
                    <div className={cn.documentsItem}>
                        <div className={cn.documentsText} dangerouslySetInnerHTML={{ __html: documentName }} />
                        {documentDescription && (
                            <div
                                className={cn.documentsDescription}
                                dangerouslySetInnerHTML={{ __html: documentDescription }}
                            />
                        )}
                    </div>
                </a>
            </div>
        </AnimatedComponent>
    );
};
export default DocumentsItem;
