/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import 'react-quill/dist/quill.snow.css';

import dynamic from 'next/dynamic';
import React, { useState } from 'react';

import AnchorIco from './AnchorIco';

type ReactQuillProps = { [key: string]: unknown };

const ReactQuill = dynamic(
    async () => {
        const { default: RQ } = await import('react-quill');
        const Inline = RQ.Quill.import('formats/link');
        const icons = RQ.Quill.import('ui/icons') as { [key: string]: string };
        icons.internal_link = AnchorIco;

        class InternalLink extends Inline {
            static blotName = 'internal_link';

            static tagName = 'span';

            static create(value: string) {
                const node = super.create(value) as Element;
                node.setAttribute('id', value);
                node.setAttribute('class', 'custom-anchor');
                return node;
            }
        }

        RQ.Quill.register('formats/internal_link', InternalLink);

        return ({ ...props }: ReactQuillProps) => <RQ {...props} />;
    },
    { ssr: false }
);

const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'background',
    'link',
    'image',
    'video',
    'color',
    'code-block',
    'internal_link',
    // 'anchor',
];

const modules = {
    toolbar: {
        container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link', 'internal_link'],
            [
                {
                    background: ['#F4F4FF', '#D0F0C0', '#FCE883', '#FFFFFF', '#000000', '#9400d3'],
                },
                {
                    color: ['#F4F4FF', '#D0F0C0', '#FCE883', '#FFFFFF', '#000000', '#9400d3'],
                },
            ],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['image', 'code-block'],
            ['clean'],
        ],
        handlers: {
            internal_link(value) {
                if (value === true) {
                    // eslint-disable-next-line no-param-reassign
                    value = prompt('Введите значение для ID:'); // eslint-disable-line no-alert
                }
                this.quill.format('internal_link', value, false);
            },
        },
    },
};

const HtmlEditor = ({ field, input, meta }) => {
    const [value, setValue] = useState<string>(input.value);

    return (
        <ReactQuill
            theme="snow"
            onChange={(content, delta, source, editor) => {
                if (
                    editor.getText() === null ||
                    editor.getText() === undefined ||
                    editor.getText() === '' ||
                    editor.getText().length === 1
                ) {
                    setValue('');
                    input.onChange('');
                } else {
                    setValue(content);
                    input.onChange(content);
                }
            }}
            value={value}
            preserveWhitespace
            formats={formats}
            modules={modules}
            defaultValue={input.value as string}
        />
    );
};

export default HtmlEditor;
