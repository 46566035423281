import React from 'react';

import { TinaTable } from '@/components/blocks/Table/index.tina.deprecated';
import { mockTariffTableBlockDataTina } from '@/data/blocks/tariffTabs/table';
import HtmlEditorFeatured from '@/tina/plugins/htmlEditorFeatured';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const tableBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaTable {...extendedProps} />;
    },
    template: {
        label: 'Таблица',
        defaultItem: () => mockTariffTableBlockDataTina,
        fields: [
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                component: 'select',
                name: 'type',
                label: 'Тип',
                options: [
                    {
                        value: 'main',
                        label: 'Основной',
                    },
                    {
                        value: 'secondary',
                        label: 'Дополнительный',
                    },
                ],
            },
            {
                component: HtmlEditorFeatured,
                name: 'html',
                label: 'Таблица',
            },
        ],
    },
};
