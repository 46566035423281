import clsx from 'clsx';
import React, { ReactNode, useRef, useState } from 'react';

import cn from './style.module.sass';

interface IProps {
    children: ReactNode | string;
    trigger: ReactNode | string;
    onClick?: () => void;
}

function CustomAccordion({ trigger, children, onClick }: IProps) {
    const [height, setHeight] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        if (ref?.current) {
            setHeight(height === 0 ? ref.current.offsetHeight : 0);
        }
        if (onClick) onClick();
    };

    return (
        <div className={clsx(cn.wrapper, height !== 0 && 'open')}>
            <div className={cn.trigger} onClick={handleClick} role="presentation">
                {trigger}
            </div>
            <div className={cn.toggle} style={{ height }}>
                <div className={cn.content} ref={ref}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CustomAccordion;
