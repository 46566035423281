import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import DocumentsItem, { IDocumentsItem } from '@/components/blocks/tariffTabs/Documents/item';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Arrow from '@/components/svg/Arrow';
import TextField from '@/components/TextField';
import Title from '@/components/UI/Title';

import cn from './style.module.sass';

export interface IDocuments {
    _template?: 'documents';
    title?: string;
    name?: string;
    subtitle?: string;
    list: IDocumentsItem[];
    isCollapsible?: boolean;
}

function getTrigger(trigger: string) {
    return (
        <div className={clsx(cn.triggerWrap)}>
            <div className={cn.trigger}>{trigger}</div>
            <div className={cn.arrow}>
                <Arrow />
            </div>
        </div>
    );
}

const Documents: React.FC<IDocuments & { index: number }> = ({
    list,
    subtitle,
    index,
    name,
    isCollapsible = false,
}) => {
    if (isCollapsible)
        return (
            <div className="section">
                <div className={cn.fullWrapper}>
                    <AnimatedComponent className={cn.docCollapsibleWrap} classNameActive={cn.animationInit}>
                        <Collapsible
                            className={cn.docCollapsible}
                            openedClassName={clsx(cn.docCollapsible, cn.open)}
                            trigger={getTrigger(name)}
                            triggerOpenedClassName={cn.open}
                            triggerTagName="div"
                            transitionTime={300}
                            easing="ease-in-out"
                        >
                            <div className={cn.documents}>
                                {subtitle && <TextField text={subtitle} name="subtitle" className={cn.subtitle} />}
                                {list?.map((item, innerIndex) => (
                                    <DocumentsItem key={`${innerIndex} - ${item.documentName}`} pos={index} {...item} />
                                ))}
                            </div>
                        </Collapsible>
                    </AnimatedComponent>
                </div>
            </div>
        );

    return (
        <div className="section">
            <div className={cn.fullWrapper}>
                {name && <Title title={name} level={3} name="name" />}
                {subtitle && <TextField text={subtitle} name="subtitle" className={cn.subtitle} />}
                <div className={cn.documents}>
                    {list?.map((item, innerIndex) => (
                        <DocumentsItem key={`${innerIndex} - ${item.documentName}`} pos={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(Documents);
