import { sectionExceptionsConfig } from '@/tina/config/sectionsExeptions.config';
import { setBlockLinks } from '@/tina/utils/setBlockLinks';
import { setSectionField } from '@/tina/utils/setSectionField';
import { ITinaBlocksConfig } from '@/types/tina/config';

export const getBlocks = (config: ITinaBlocksConfig) => {
    const nameSpaces = Object.keys(config);

    return nameSpaces.reduce(
        (acc, block) => ({
            ...acc,
            [block]: setSectionField(setBlockLinks(config[block]), block, sectionExceptionsConfig),
        }),
        {}
    );
};
