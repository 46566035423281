import clsx from 'clsx';
import React from 'react';

import Arrows from '@/components/blocks/Questions/arrows';
import cn from '@/components/blocks/tariffTabs/TariffBlocks/style.module.sass';

interface ITrigger {
    title: string;
}

const Trigger: React.FC<ITrigger> = ({ title }) => (
    <div className={clsx(cn.triggerWrap)}>
        <div className={cn.trigger}>
            <div className={clsx(cn.tariffSubtitle, 'headers')} dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={cn.arrow}>
            <Arrows />
        </div>
    </div>
);

export default Trigger;
