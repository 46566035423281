import Table from '@/components/blocks/Table';
import Documents from '@/components/blocks/tariffTabs/Documents';
import Help from '@/components/blocks/tariffTabs/Help';
import Tariff from '@/components/blocks/tariffTabs/TariffBlocks';

export const tariffTabsConfig = {
    help: Help,
    documents: Documents,
    tariff: Tariff,
    table: Table,
};
