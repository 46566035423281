import React, { useContext, useLayoutEffect, useState } from 'react';

import Tariff from '@/components/blocks/tariffTabs/TariffBlocks/index';
import withBlocksControl from '@/components/HOC/withBlocksControl';
import TabContext from '@/context/TabContext';
import { IBlockProps } from '@/types/tina/block';

const WrappedBlock = withBlocksControl(Tariff);

export function TinaTariff(props: IBlockProps & { index: number }) {
    const { index } = props;
    const { activeTab } = useContext(TabContext);
    const [isActive, setIsActive] = useState(activeTab === index);

    useLayoutEffect(() => {
        setIsActive(activeTab === index);
    }, [activeTab, index, isActive]);

    if (!isActive) return null;

    return <WrappedBlock {...props} />;
}
