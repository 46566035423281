import { observer } from 'mobx-react-lite';
import React from 'react';

// eslint-disable-next-line import/no-cycle
import DefaultLayout from '@/components/blocks/tariffTabs/_deprecated/layouts/DefaultLayout';
import TinaLayout from '@/components/blocks/tariffTabs/_deprecated/layouts/TinaLayout';
import { useAppStore } from '@/context/AppStoreContext';
import { IDefaultBlock } from '@/types/tina/block';

interface IProps {
    blocks: IDefaultBlock[];
}

const TariffTabsLayout: React.FC<IProps> = observer(({ blocks }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    if (isTinaView) return <TinaLayout />;

    return <DefaultLayout blocks={blocks} />;
});

export default TariffTabsLayout;
