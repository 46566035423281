import clsx from 'clsx';
import React from 'react';
import Collapsible from 'react-collapsible';

import AnimatedComponent from '@/components/Animation';
import TariffItemContent from '@/components/blocks/tariffTabs/TariffBlocks/itemContent';
import Trigger from '@/components/blocks/tariffTabs/TariffBlocks/trigger';
import { ITariffItem } from '@/types/tariffBlocks';

import cn from './style.module.sass';

const TariffItem: React.FC<ITariffItem & { pos: number }> = ({
    title,
    pos,
    info,
    conditions,
    isConditionsActive = true,
    containerType,
    isDropdown,
    isDropdownDefaultHide,
}) => {
    if (isDropdown) {
        return (
            <AnimatedComponent
                className={clsx(cn.tariffBlock, containerType === 'secondary' && cn.tariffBlockSecondary)}
                classNameActive={cn.animationInit}
            >
                <Collapsible
                    className={cn.questionsCollapsible}
                    openedClassName={clsx(cn.questionsCollapsible, cn.open)}
                    triggerOpenedClassName={cn.open}
                    trigger={<Trigger title={title} />}
                    transitionTime={300}
                    open={!isDropdownDefaultHide}
                    easing="ease-in-out"
                >
                    <TariffItemContent conditions={conditions} info={info} isConditionsActive={isConditionsActive} />
                </Collapsible>
            </AnimatedComponent>
        );
    }

    return (
        <AnimatedComponent
            className={clsx(cn.tariffBlock, containerType === 'secondary' && cn.tariffBlockSecondary)}
            classNameActive={cn.animationInit}
        >
            <div className={cn.tariffSubtitleWrap}>
                <h2 className={cn.tariffSubtitle} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <TariffItemContent conditions={conditions} info={info} isConditionsActive={isConditionsActive} />
        </AnimatedComponent>
    );
};
export default TariffItem;
