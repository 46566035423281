import React from 'react';

import { TinaDocuments } from '@/components/blocks/tariffTabs/Documents/index.tina.deprecated';
import { mockDocumentsBlockData } from '@/data/blocks/tariffTabs/documents';
import { IBlockProps } from '@/types/tina/block';

interface IExtendedBlockProps extends IBlockProps {
    index: number;
}

export const documentsBlock = {
    Component: (props: IBlockProps) => {
        const { data, index } = props;

        const extendedProps: IExtendedBlockProps = {
            ...props,
            data: {
                ...data,
                index,
            },
        };

        return <TinaDocuments {...extendedProps} />;
    },
    template: {
        label: 'Документы',
        defaultItem: () => mockDocumentsBlockData,
        fields: [
            {
                name: 'section',
                component: 'section',
                label: 'Привязка к разделу',
            },
            {
                component: 'text',
                name: 'title',
                label: 'Заголовок',
            },
            {
                label: 'Список элементов',
                name: 'list',
                component: 'group-list',
                itemProps: (item: { documentName: string }, index: number) => ({
                    key: index,
                    label: item.documentName,
                }),
                defaultItem: () => mockDocumentsBlockData.list[0],
                fields: [
                    {
                        component: 'text',
                        name: 'documentName',
                        label: 'Заголовок',
                    },
                    {
                        component: 'text',
                        name: 'document',
                        label: 'Ссылка',
                    },
                    {
                        component: 'text',
                        name: 'permanentDocument',
                        label: 'Ссылка для редиректа',
                    },
                ],
            },
        ],
    },
};
